import { load, remove } from '../../../Utils/LocalStorage';
import { createReducer } from '@reduxjs/toolkit';

import {
  setUserData,
  setAuthState,
  userLogout,
} from '../../Actions/UserActions';


const usr = load('USR');
const tkn = load('TKN');

const userData = usr ? JSON.parse(usr) : null;
const { tokenAccess, tokenRefresh, sid } = tkn
  ? JSON.parse(tkn)
  : { tokenAccess: null, tokenRefresh: null, sid: null };

export const UserState = {
  name: userData ? userData.name || 'Anonymous' : 'Anonymous',
  email: userData ? userData.email : 'account',
  Auth: userData ? true : false,
  tokenAccess,
  tokenRefresh,
  sid,
};

const user = createReducer(UserState, {
  [setAuthState]: (state, action) => {
    state.Auth = action.payload;
  },
  [setUserData]: (state, action) => {
    return { ...state, ...action.payload };
  },
  [userLogout]: () => {
    return {
      name: 'Anonymous',
      email: 'account',
      Auth: false,
      token: null,
    };
  },
});

// export const token = createReducer(null, {
//   [setAuthState]: (_, { payload }) => payload.token,
//   [setUserName]: (_, { payload }) => payload.token,
//   [userLogout]: () => null,
// });

export default user;
