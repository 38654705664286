import s from './backdrop.module.scss';
import './backdrop.css';
import { useRef } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

export const BackDrop = ({ isIn, children, bg, childPosition = '' }) => {
  const ref = useRef(null);
  return (
    <CSSTransition
      in={isIn}
      nodeRef={ref}
      timeout={0}
      classNames="backdrop"
      unmountOnExit>
      <div
        ref={ref}
        className={`${s.backdrop} ${s[childPosition]}`}
        style={{ background: bg }}>
        {children}
      </div>
    </CSSTransition>
  );
};

BackDrop.propTypes = {
  isIn: PropTypes.bool.isRequired,
  bg: PropTypes.string,
  childPosition: PropTypes.string,
};
