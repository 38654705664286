import { createReducer } from '@reduxjs/toolkit';

import {
  removeCardByID,
  setCardDoneByID,
  getCardByIDandUpdate,
} from '../../Selectors/DashboardSelectors';

import {
  getCardsToStore,
  addCardToStore,
  deleteCardFromStore,
  setCardInStoreDone,
  updateCardInStore,
} from '../../Actions/DashboardActions';

const dashboardState = {
  today: [],
  tomorrow: [],
  rest: [],
  done: [],
};

const dashboard = createReducer(dashboardState, {
  [getCardsToStore]: (state, action) => {
    return action.payload;
  },

  [addCardToStore]: (state, action) => {
    state.today.unshift(action.payload);
  },

  [deleteCardFromStore]: (state, action) => {
    return removeCardByID({ dashboard: state }, action.payload);
  },

  [setCardInStoreDone]: (state, action) => {
    return setCardDoneByID({ dashboard: state }, action.payload);
  },

  [updateCardInStore]: (state, action) => {
    return getCardByIDandUpdate({ dashboard: state }, action.payload);
  },
});

export default dashboard;
