const types = {
  ADD_MESSAGE: 'message/set',
  CLEAR_MESSAGE: 'message/clear',
  SET_LOADING_STATE: 'message/set-loading',
  SET_AUTH: 'auth-state/set',
  SET_USER_NAME: 'user-name/set',
  SET_LOGOUT: 'user/logout',
  GET_CARDS: 'card/getall',
  ADD_CARD: 'card/add',
  DELETE_CARD: 'card/delete',
  SET_CARD_DONE: 'card/done',
  UPDATE_CARD: 'card/update',
  SET_EDIT_CARD: 'card/edit',
  CHANGE_FILTER: 'card/filter',
};

export default types;
