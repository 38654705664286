import { createAction } from '@reduxjs/toolkit';

import types from '../actionTypes';

const getCardsToStore = createAction(types.GET_CARDS);

const addCardToStore = createAction(types.ADD_CARD);

const deleteCardFromStore = createAction(types.DELETE_CARD);

const setCardInStoreDone = createAction(types.SET_CARD_DONE);

const updateCardInStore = createAction(types.UPDATE_CARD);

const filterCategories = createAction(types.CHANGE_FILTER);

export {
  getCardsToStore,
  addCardToStore,
  deleteCardFromStore,
  setCardInStoreDone,
  updateCardInStore,
  filterCategories,
};
