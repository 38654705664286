import { createReducer } from '@reduxjs/toolkit';

import { filterCategories } from '../../Actions/DashboardActions';

const filterState = {
  today: null,
  tomorrow: null,
  rest: null,
  done: null,
};

const filter = createReducer(filterState, {
  [filterCategories]: (state, action) => {
    return { ...state, ...action.payload };
  },
});

export default filter;
