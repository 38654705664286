import { createAction } from '@reduxjs/toolkit';

import types from '../actionTypes';

const setLoadingState = createAction(types.SET_LOADING_STATE);

const addMessage = createAction(types.ADD_MESSAGE);

const clearMessage = createAction(types.CLEAR_MESSAGE);

export { setLoadingState, addMessage, clearMessage };
