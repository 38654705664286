import { Suspense } from 'react';
import Loader from 'react-loader-spinner';
import { BackDrop } from '../Components/UI/Backdrop/';

const WithSuspense = (Component) => (props) =>
  (
    <Suspense
      fallback={
        <BackDrop
          isIn={true}
          childPosition={'centered'}
          bg={'rgba(255, 255, 255, 0.2)'}>
          <Loader type="Oval" color="#00D7FF" height={80} width={80} />
        </BackDrop>
      }>
      <Component {...props} />
    </Suspense>
  );

export default WithSuspense;
