import { createSelector } from 'reselect';

export const getAllCards = ({ dashboard }) => {
  return dashboard;
};

export const getTodayCards = ({ dashboard }) => dashboard.today;

export const getCardsLength =
  (title) =>
  ({ dashboard }) => {
    const totalCards = dashboard[title];
    return totalCards.length;
  };

export const getFilter =
  (title) =>
  ({ filter }) =>
    filter[title];

export const getTomorrowCards = ({ dashboard }) => dashboard.tomorrow;

export const getRestCards = ({ dashboard }) => dashboard.rest;

export const getDoneCards = ({ dashboard }) => dashboard.done;

export const getCardByID = (dashboard, id) => {
  let cardByID = null;
  for (let category in dashboard) {
    dashboard[category].map((card) => {
      if (card._id === id) cardByID = card;
      return card;
    });
  }
  return cardByID;
};

export const setCardDoneByID = createSelector(
  [(store, id) => id, getAllCards],
  (id, dashboard) => {
    const cardDone = getCardByID(dashboard, id);
    // for (let category in dashboard) {
    //   dashboard[category] = dashboard[category].filter(
    //     (card) => card._id !== id,
    //   );
    // }
    cardDone.done = true;
    // if (!dashboard.done.find(({ _id }) => _id === id))
    //   dashboard.done.unshift(cardDone);
    return dashboard;
  },
);

export const getCardByIDandUpdate = createSelector(
  [(store, body) => body, getAllCards],
  (body, dashboard) => {
    const card = getCardByID(dashboard, body.id);
    for (let key in body.card) {
      if (card[key]) card[key] = body.card[key];
    }
    return dashboard;
  },
);

export const removeCardByID = createSelector(
  [(store, id) => id, getAllCards],
  (id, dashboard) => {
    for (let category in dashboard) {
      dashboard[category] = dashboard[category].filter(
        (card) => card._id !== id,
      );
    }
    return dashboard;
  },
);
