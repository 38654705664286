import { createReducer } from '@reduxjs/toolkit';

import {
  setLoadingState,
  addMessage,
  clearMessage,
} from '../../Actions/MessageActions';

const messageState = {
  loading: false,
  error: null,
  success: null,
};

const message = createReducer(messageState, {
  [setLoadingState]: (state, action) => {
    state.loading = action.payload;
  },

  [addMessage]: (state, action) => {
    return { ...state, ...action.payload };
  },

  [clearMessage]: () => messageState,
});

export default message;
