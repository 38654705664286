import { createReducer } from '@reduxjs/toolkit';

import { setEditCard } from '../../Actions/EditCardActions';

const editState = { id: '' };

const cardToEdit = createReducer(editState, {
  [setEditCard]: (state, action) => {
    state.id = action.payload;
  },
});

export default cardToEdit;
