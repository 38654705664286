import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Loader from 'react-loader-spinner';

import { routes } from './Routes';
import { getUserData } from './Redux/Selectors/UserSelectors';
import { clearMessage } from './Redux/Actions/MessageActions';
import { BackDrop } from './Components/UI/Backdrop/index';

function App() {
  const { email, Auth } = useSelector(getUserData);

  const { loading, error, success } = useSelector(({ message }) => message);

  const dispatch = useDispatch();

  useEffect(() => {
    const toastConfig = {
      position: 'top-right',
      autoClose: 5000,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    };
    if (error) toast.error(error, toastConfig);
    if (success) toast.success(success, toastConfig);
    dispatch(clearMessage());
  }, [error, success, dispatch]);

  const username = email.split('@')[0];

  return (
    <>
      <BackDrop isIn={loading} childPosition={'centered'}>
        <Loader type="Oval" color="#00D7FF" height={80} width={80} />
      </BackDrop>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Switch>
        {routes.map(({ path, exact, comp, auth, title }, i) => {
          return (
            <Route key={i} exact={exact} path={path}>
              {({ match, ...props }) => {
                return Auth !== auth ||
                  (match.params.account &&
                    match.params.account !== username) ? (
                  <Redirect to={Auth ? `/${username}/dashboard` : '/signup'} />
                ) : (
                  comp({ title, match, ...props })
                );
              }}
            </Route>
          );
        })}
        <Redirect to={Auth ? `/${username}/dashboard` : '/signup'} />
      </Switch>
    </>
  );
}

export default App;
