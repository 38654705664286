import { configureStore, combineReducers } from '@reduxjs/toolkit';

import user from './Redusers/UserReduser';
import dashboard from './Redusers/DashboardReduser';
import message from './Redusers/MessageReducer/';
import cardToEdit from './Redusers/EditCardReducer/';
import filter from './Redusers/DashboardReduser/filterReducer';

const reducer = combineReducers({
  user,
  dashboard,
  message,
  cardToEdit,
  filter,
});

const thunk =
  ({ dispatch, getState }) =>
  (next) =>
  (action) =>
    typeof action === 'function' ? action(dispatch, getState) : next(action);

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

export default store;
