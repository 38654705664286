import { createAction } from '@reduxjs/toolkit';

import types from '../actionTypes';

const setAuthState = createAction(types.SET_AUTH);

const setUserData = createAction(types.SET_USER_NAME);

const userLogout = createAction(types.SET_LOGOUT);

export { setAuthState, setUserData, userLogout };
